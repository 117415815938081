import {Tab} from 'bootstrap';
export function initNextButton() {
    const nextBtn = document.querySelector('#nextButton');
    const form = document.querySelector('.events-form-js');
    if(nextBtn) {
        const pid = form.querySelector('[name=postId]').value;
        const userMail = form.querySelector('[name=email]');
        const isEvent = form.querySelector('[name=isEvent]').value;

        nextBtn.addEventListener('click', () => {
            const isValid = form.reportValidity();


            if(isValid && isEvent != 0) {
                validateEventForm(pid, userMail.value);
            }
            if(isValid && isEvent == 0) {
                showSummary();
            }
        })
    }
}

function showSummary() {
    const summaryTab = document.querySelector('#summary-tab');
    summaryTab.classList.remove('disabled');
    const tab = Tab.getOrCreateInstance(summaryTab) // Returns a Bootstrap tab instance
    tab.show();

    const form = document.querySelector('.events-form-js.events-validation-js');
    form.scrollTo();
    const summaryContainer = document.querySelector('#summary-container');
    summaryContainer.innerHTML = '';
    for(let node of form) {
        if(node.nodeName === 'INPUT' && node.type !== 'hidden' && node.type !== 'checkbox' && node.type !== 'radio' && node.type !== 'file') {
            summaryContainer.appendChild(createSummaryRow(node.labels[0].innerText, node.value));
        }

        if(node.nodeName === 'INPUT' && node.type !== 'hidden' && (node.type === 'checkbox' || node.type === 'radio')) {

        }

        if(node.nodeName === 'INPUT' && node.type !== 'hidden' && node.type === 'file') {
            console.log(node.files);
            const embedNode = createFilePreview(node.files[0]);
            const row = createSummaryRow(node.labels[0].innerText, '')
            row.querySelector('#' + node.labels[0].innerText).appendChild(embedNode);
            summaryContainer.appendChild(row);

        }
    }

function createFilePreview(file) {
    const image = document.createElement('embed');
    image.src = URL.createObjectURL(file);
    return image;
}

}

function createSummaryRow(label, value) {
    let html = `
        <div class="row g-md-3 align-items-center">
            <div class="col-12 col-md-4 fw-bold">
                <span class="col-form-label">${label}</span>
            </div>
            <div class="col-12 col-md-8">
                <div id="${label}" class="">${value}</div>
            </div>
        </div>`

    const node = document.createElement('div');
    node.innerHTML = html
    return node;
}

function validateEventForm(pid, userMail) {

    if(pid && userMail) {
        checkFormValidation(pid, userMail).then(resp =>  {

            console.log(resp);

            if(!resp.error) {
                showSummary();
            } else {
                handleError(resp);
            }
        });
    }
}

function handleError(error) {
    const formErrorContainer = document.querySelector('.error-container');

    let html = `
        <h3 class="mt-4">${error.error}</h3>
        <p>${error.message}</p>`

    formErrorContainer.innerHTML = html;
}

async function checkFormValidation(pid, email) {
    const apiEndpoint = '/wp-json/rw-events/v1/validate/';
    const response = await fetch(apiEndpoint, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            pid: pid,
            email: email,
        })
    });
    return await response.json(); // parses JSON response into nati
}


