// You can specify which plugins you need
import { Tooltip, Toast, Popover, Tab } from 'bootstrap';

import './mmenu/index.js';
import './splide/index.js';

import {initNextButton} from "./events/formCheck.js";
import {checkForm} from "./events/formCheck.js";

initNextButton();

window.onscroll = function() {
    scrollFunction()
};
const header = document.getElementsByTagName('header');

function scrollFunction() {
    if (document.body.scrollTop > 150 || document.documentElement.scrollTop > 150) {
            header[0].classList.add('fixedBar');
    }
    else {
        if(document.documentElement.scrollTop < 100){
            header[0].classList.remove('fixedBar');
        }
    }

}




